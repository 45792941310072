<template>
  <div class="app-container">
    <el-row class="margin-bottom-20" justify="center">
      <el-col :lg="18" :md="16" :sm="16" :xs="12">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="ID cliente" v-model="searchForm.query" v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col style="display: flex; justify-content: flex-start;" :md="4" :sm="8" :xs="10">
        <el-button
          @click.stop.prevent="handleSearchFilter"
          class="margin-left-10"
          size="mini"
          type="primary"
          icon="el-icon-search"
        >Buscar</el-button>
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleClean"
          size="mini"
          type="warning"
          icon="el-icon-delete"
        >Limpiar Filtros</el-button>
      </el-col>
      <!-- <el-col :lg="2" :xs="8" :offset="0">
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleExport"
          size="mini"
          type="success"
          icon="el-icon-bottom"
        >Exportar</el-button>
      </el-col> -->
    </el-row>
    <el-row justify="center">
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="8">
          <el-select v-model="searchForm.payment_type" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Tipo de pago">
            <el-option
              v-for="state in stateTypes"
              :key="state"
              :label="state"
              :value="state">
            </el-option>
          </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="8">
          <el-select v-model="searchForm.payment_method" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Metodo">
            <el-option
              v-for="state in stateMethods"
              :key="state"
              :label="state"
              :value="state">
            </el-option>
          </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="8">
          <el-select v-model="searchForm.payment_status" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Estatus">
            <el-option
              v-for="itemS in status"
              :key="itemS.status"
              :label="itemS.status"
              :value="itemS.status">
            </el-option>
          </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="12" :md="12" :sm="14" :xs="24">
        <div style="text-align: center;" class="grid-content bg-purple">
          <span class=" margin-filter">Registros por fecha</span>
          <el-date-picker
            v-model="filterDateRegisterRange"
            v-on:change="handleFilterDateRegister()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :lg="24" :md="24" :sm="8" :xs="24" style="display: flex; justify-content: flex-end">
        <el-row type="flex" justify="end">
          <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
            :total="parseInt(totalPages)">
          </el-pagination>
        </el-row>
      </el-col>
    </el-row>
    <el-table
      size="mini"
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      height="600"
      fit
      border
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column label="ID Orden" fixed width="120" align="center">
        <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">{{scope.row._id}}</div>
                <span>{{ scope.row._id.toString().substr(-4) }}</span>
            </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="ID Cliente" width="120" align="center">
        <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">{{scope.row.customer}}</div>
                <span>{{ scope.row.customer.toString().substr(-4) }}</span>
            </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="ID Orden Conekta" width="130" align="center">
        <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">{{scope.row.order_id}}</div>
                <span>{{ scope.row.order_id.toString().substr(-4) }}</span>
            </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Tipo" width="130" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.metadata.form }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Metodo de pago" width="140" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.type }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Monto" width="100" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.amount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Referencia" width="130" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.reference }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Estado del pago" width="170" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.status == 'Paid'">
            <el-tag type="success">{{ scope.row.status }}</el-tag>
          </div>
          <div v-else-if="scope.row.status == 'Pending'">
            <el-tag>{{ scope.row.status }}</el-tag>
          </div>
          <div v-else>
            <el-tag type="danger">{{ scope.row.status }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Fecha registro" width="150" align="center">
        <template slot-scope="scope" >
         <span>{{ scope.row.created_at | formatDateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha de expiración del pago" width="160" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.expires_at | formatDateTime }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
    <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
      :total="parseInt(totalPages)">
    </el-pagination>
  </el-row>
  </div>
</template>

<script>
import { search, getPage, getStatus, exportExcel } from '@/api/ordersConekta'

export default {
  data () {
    return {
      searchForm: {
        query: '',
        payment_method: '',
        payment_status: '',
        payment_type: '',
        initDate: '',
        endDate: ''
      },
      filterDateRegisterRange: '',
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      totalPages: '',
      currentPage: '',
      list: null,
      loading: false,
      sizePerPage: 25,
      stateMethods: ['Efectivo (Oxxo)', 'Transferencia (SPEI)', 'Tarjeta'],
      status: null,
      stateTypes: ['Lead', 'Ex-alumno']
    }
  },
  mounted () {
    this.handleSearchFilter()
    this.handleGetStatus()
  },
  methods: {
    handleClean () {
      this.filterDateRegisterRange = []
      this.searchForm = {
        query: '',
        payment_method: '',
        payment_status: '',
        payment_type: '',
        initDate: '',
        endDate: ''
      }
      this.handleSearchFilter()
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleGetStatus () {
      this.loading = true
      await getStatus()
        .then((response) => {
          this.status = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleFilterDateRegister () {
      if (this.filterDateRegisterRange) {
        this.searchForm.initDate = this.filterDateRegisterRange[0]
        this.searchForm.endDate = this.filterDateRegisterRange[1]
        this.handleSearchFilter()
      } else {
        this.searchForm.initDate = ''
        this.searchForm.endDate = ''
        this.handleSearchFilter()
      }
    },
    async handleExport () {
      this.loading = true
      await exportExcel(
        this.searchForm
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        const name = 'Reporte-de-pagos-Conekta-' + new Date() + '.xlsx'
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        console.log('Archivo Exportado')
      })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    }
  }
}
</script>
